.arrival-address-autocomplete {
    font-size: 14px;
    padding: 15px 20px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #d9d9d9;
}

.arrival-address-autocomplete::placeholder {
    color: #9299B8;
}

.arrival-address-autocomplete:hover, .arrival-address-autocomplete:focus {
    border-color: #40a9ff !important;
    border-width: 1px !important;
    outline: none;
}

.pac-container:after {
    background-image: none !important;
    height: 0px;
}

.pac-icon, .address-icon  {
    background-image: url('../../../static/img/icon/places_input_marker.png')!important;
    background-size: contain;
    background-repeat: no-repeat;    
    background-position: center;
    position: absolute;
    top: 31px;
    margin: 0px;
}

.pac-item-selected .pac-icon, .address-item .address-icon  {
    background-image: url('../../../static/img/icon/selected_place_input_marker.png')!important;
    background-size: contain;
    background-repeat: no-repeat;    
    background-position: center;
    position: absolute;
    top: 31px;
    margin: 0px;
}

.pac-item {
    padding: 16px 25px;
    position: relative;
    border-top: none;
}

.pac-item-query {
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    padding-left: 40px;
}

.pac-item-query + span {
    display: block;
    padding-left: 40px;
    font-size: 14px;
    line-height: 20px;
    color: #545454;
}

input.arrival-address-autocomplete.pac-target-input:disabled {
  border-color: #d8d3d3 !important;
  background-color: #f5f5f5;
  cursor: not-allowed;
  color: #c8c0c1;
}
